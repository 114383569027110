<script>
import { notificationMixins } from "../../../mixins/notification";
import Popup from "../../common/Popup.vue";
import {userMixins} from "@/mixins/user";

export default {
  mixins: [userMixins, notificationMixins],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  components: {
    Popup,
  },
  data() {
    return {
    };
  },
  methods: {
    async deactivateConfirmed() {
      await this.deactivateUser(this.user.id);
      this.showSuccess(this.$t("user-deactivated", { item: this.user.name}));
      this.$emit("closed", true);
    },
  },
};
</script>

<template>
  <Popup @cancelled="$emit('closed', false)" @confirmed="deactivateConfirmed">
    <template v-slot:header>{{ $t("deactivate-user") }}</template>
    <template v-slot:body>
      <div class="message">{{ $t("deactivate-user-message", { item: user.name }) }}</div>
      <div class="message">{{ $t("deactivate-user-message-confirm") }}</div>
    </template>
  </Popup>
</template>

<style lang="scss" scoped>
  .message {
    margin-bottom: 1.5em;
  }
</style>