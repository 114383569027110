<script>
import Users from "./Users.vue";
import { mapState } from "vuex";

export default {
  components: {
    Users,
  },
  computed: {
    ...mapState({
      permitted: (state) => state.auth.userData?.isAdmin ?? false,
    }),
  },
  mounted() {
    this.$store.dispatch('setBreadcrumbs', [{ to: { name: 'users' }, label: this.$t('users') }]);
  },
};
</script>

<template>
  <Users v-if="permitted" />
  <p v-else>You do not have permission to manage users.</p>
</template>
