<script>
import {apiMixins} from "../../../mixins/api.js";
import {notificationMixins} from "../../../mixins/notification";
import {folderMixins} from "@/mixins/folder";
import { subjectMixins } from "../../../mixins/subject.js";
import Metadata from "../../common/Metadata.vue";
import RetentionSchedule from "./RetentionSchedule.vue";

export default {
  components: { Metadata, RetentionSchedule },
  mixins: [apiMixins,folderMixins,notificationMixins,subjectMixins],
  props: {
    item: {
      type: Object,
      required: true,
    },
    additionalFolderProperties: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      suspensionOrders: this.item.suspensionOrders,
      steward: null,
      currentSubjects: this.item.tags,
      allSubjects: [],
      currentMetadata: this.item.metadata,
      groupRegister: this.item.groupRegister,
      currentRetentionSchedule: this.item.defaultRetention,
    };
  },
  computed: {
    availableSubjects() {
      return this.allSubjects.filter(s => !this.currentSubjects.includes(s.label));
    },
    canAddTag() {
      return (
        !this.item.suspended &&
        !this.item.trashRepository &&
        (this.$store.state.auth.userData.isAdmin ||
        this.item.steward.includesMe ||
        this.item.owner.includesMe)
      );
    },
    canDeleteTag() {
      return (
        !this.item.suspended &&
        !this.item.trashRepository && 
        (this.$store.state.auth.userData.isAdmin ||
        this.item.steward.includesMe ||
        this.item.owner.includesMe)
      );
    },
  },
  methods: {
    returnToBrowse() {
      this.$router.push({name: "browse", params: {id: this.item.parentId}});
    },
    async submitPropertiesUpdate() {
      let update = {};
      if (this.suspensionOrders !== this.item.suspensionOrders){
        update.suspensionOrders = this.suspensionOrders
      }
      if (this.steward !== this.item.steward.name){
        update.steward = this.steward
      }
      update.tags = this.currentSubjects;
      update.metadata = this.currentMetadata;
      update.groupRegister = this.groupRegister;
      update.defaultRetention = this.currentRetentionSchedule?.id || null;
      await this.updateFolder(this.item, update);
      this.showSuccess(this.$t("folder-updated"));
      this.returnToBrowse();
    },
    async cancelPropertiesUpdate() {
      this.returnToBrowse();
    },
    totalSizeInBytes() {
      if (this.additionalFolderProperties === null) {
        return "0 bytes";
      }
      return this.additionalFolderProperties.totalSize.toLocaleString() + " bytes";
    },
    totalSizeInMegabytes() {
      if (this.additionalFolderProperties === null) {
        return "0 MB";
      }
      return (this.additionalFolderProperties.totalSize / 1024 / 1024).toLocaleString() + " MB";
    },
    totalSizeInGigabytes() {
      if (this.additionalFolderProperties === null) {
        return "0 GB";
      }
      return (this.additionalFolderProperties.totalSize / 1024 / 1024 / 1024).toLocaleString() + " GB";
    },
    tagAdded(tag) {
      this.currentSubjects.push(tag.value);
    },
    tagRemoved(tag) {
      this.currentSubjects = this.currentSubjects.filter(s => s !== tag);
    },
    async loadSubjects() {
      var response = await this.getAvailableSubjects(this.folderId, { showLoader: true });
      this.allSubjects = response.subjects;
    },
    metadataChanged(metadata) {
      this.currentMetadata = metadata;
    },
    onRetentionScheduleChangedHandler(selectedRetentionSchedule) {
      this.currentRetentionSchedule = selectedRetentionSchedule;
    },
  },
  created() {
    this.loadSubjects();
  }
};
</script>

<template>
  <div class="upload">
    <section class="general">
      <h5>General</h5>
      <section>
        <div>
          <label>{{ $t("total-size") }}:&nbsp;</label>
          <span>{{ this.totalSizeInBytes() }} ({{ this.totalSizeInMegabytes() }}, {{ this.totalSizeInGigabytes() }})</span>
        </div>
        <div style="margin-top: 1rem">
          <label>{{ $t("number-of-files") }}:&nbsp;</label>
          <span>{{ this.additionalFolderProperties.numberOfFiles.toLocaleString() }}</span>
        </div>
      </section>
    </section>
    <RetentionSchedule
      id="retention-schedule"
      :item="this.item"
      @retention-schedule-changed="onRetentionScheduleChangedHandler"
    />
    <section class="team">
      <h5>Steward - {{ this.item.steward.name }}</h5>
      <section v-if="$store.state.auth.userData.isAdmin || this.item.steward.includesMe || this.item.owner.includesMe">
        <div>
          <label>
            {{ $t("properties.new-steward") }}:
            <input type="text" v-model="steward" :disabled="item.trashRepository"/>
          </label>
        </div>
      </section>
    </section>
    <section class="suspension" >
      <h5>{{ $t("suspension-orders") }}</h5>
      <section>
        <div v-if="item.suspended && (!item.suspensionOrders || item.suspensionOrders.length === 0)">
          <p>{{ $t('suspension-order-parent') }}</p>
          <br>
        </div>
        <p>{{  $t("suspension-order-meaning") }}</p>
        <p>{{ $t("suspension-order-instruction") }}</p>
        <div style="margin-top: 1rem">
        <label>
          {{ $t("suspension-order-input-label") }}:
          <input type="text" v-model="suspensionOrders" :disabled="item.trashRepository">
        </label>
        </div>
      </section>
    </section>
    <section class="tags">
        <h5>{{ $t("keywords-heading") }}</h5>
        <section>
          <p>{{ $t("keywords-description-add") }}</p>
          <m-select
          id="add-tag"
          outlined
          @change="tagAdded"
          style="margin-top: 1rem"
          :disabled="!canAddTag"
        >
          <option selected value="">{{ $t("keywords-add") }}</option>
          <option
            v-for="subject in availableSubjects"
            :key="subject.id"
            :value="subject.label"
            >{{ subject.label }}</option
          >
        </m-select>
        <m-chip-set v-if="currentSubjects && currentSubjects.length" >
          <m-chip v-for="subject in currentSubjects" :key="subject" shouldRemoveOnTrailingIconClick="false">{{ subject }}
            <m-icon icon="cancel" slot="trailingIcon" @click="tagRemoved(subject)" v-if="canDeleteTag"/>
          </m-chip>
        </m-chip-set>
        </section>
     
      </section>

      <Metadata :item="item" @metadata-changed="metadataChanged"/>

       <section class="group-register" v-if="$store.state.auth.userData.isAdmin && item.parentId != null && item.parentId !== 0">
        <h5>{{ $t("group-register-section") }}</h5>
        <section>
        <label>
          <input
            type="checkbox"
            v-model="groupRegister"
            :disabled="item.trashRepository"
          />
          {{ $t("group-register-existing-folder") }}
        </label>
      </section>
      </section>

    <section class="buttons" >
      <button class="primary"  @click="submitPropertiesUpdate" :disabled="item.trashRepository">
        {{ $t("folder-properties-confirm") }}
      </button>
      <button @click="cancelPropertiesUpdate">
        {{ $t("cancel") }}
      </button>
    </section>
  </div>
</template>

<style lang="scss" scoped>
// .upload {
//   padding-top: 1rem;
// }

h5 {
  font-weight: bold;
  margin-bottom: 1rem;
}

.options {
  display: flex;
  background: white;
  font-size: 0.875rem;

  > div {
    padding: 1.5rem;

    &:not(:last-child) {
      border-right: 1px solid #E0E0E0;
    }
  }
}

.confidentiality, .expiry, .suspension, .team, .general, .tags, .group-register {
  > h5 {
    margin: 2rem 1rem 0;
  }

  > section {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    background: white;
    padding: 1.5rem;

    // div {
    //   margin-bottom: 1.5rem;
    // }

    label {
      margin-left: 1rem;
      font-weight: bold;
    }

    input[type=text] {
      background: #F9F9F9;
      border: 1px solid #E5E5E5;
      border-radius: 8px;
      color: black;
      padding: 0.5rem 1rem;
    }

    input[type=checkbox] {
      margin-right: 0.5rem;
    }
  }

  select {
    margin: 1rem 0
  }

  .warning {
    color: #EF0000;
  }
}

.progress {
  padding: 1.5rem 1.5rem 2rem;
  background: white;
  font-size: 0.875rem;
  border-top: 1px solid #E0E0E0;
}

.buttons {
  margin-top: 1rem;
}

.mdc-select,
.mdc-text-field {
  background-color: white;
  padding-left: 0;
  border-radius: 0;
}

.mdc-select .mdc-select__native-control,
.mdc-select .mdc-floating-label {
  padding-left: 0.5rem;
}

input[type=checkbox]{
  background-color: #fff;
  padding-top: 0;
  padding-left: 0.1rem;
}

.relative-dates a.chip-trigger {
  display: block;
  padding: 0.5rem;
}

.relative-dates .mdc-chip {
  padding: 0;
}

.buttons button {
  margin-right: 1rem;
}

.buttons button {
  background: transparent;
  border: 1px solid #1275CC;
  color: #1275CC;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  font-weight: bold;
  cursor: pointer;
  line-height: 1.6rem;

  &:disabled {
    cursor: default;
    opacity: 0.2;
  }

  &.primary {
    background: #1275CC;
    border-color: transparent;
    color: white;
  }

  > i {
    vertical-align: -0.35rem;
    padding-right: 0.25rem;
  }
}
</style>
