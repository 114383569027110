<script>
import { notificationMixins } from "../../../mixins/notification";
import Popup from "../../common/Popup.vue";
import {userMixins} from "@/mixins/user";

export default {
  mixins: [userMixins, notificationMixins],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  components: {
    Popup,
  },
  data() {
    return {
    };
  },
  asyncComputed: {
    membership() {
      return this.getTeamsForUser({ userId: this.user.id} );
    }
  },
  methods: {
    async deactivateConfirmed() {
      await this.deactivateUser(this.user.id);
      this.showSuccess(this.$t("user-deactivated", { item: this.user.name}));
      this.$emit("closed", true);
    },
  },
};
</script>

<template>
  <Popup @cancelled="$emit('closed', false)">
    <template v-slot:header>{{ $t("membership-title", { name: user.name }) }}</template>
    <template v-slot:body>
      <div class="message">{{ $t("membership-message") }}</div>
      <ul class="teams" v-if="membership && membership.teams">
        <li v-for="team in membership.teams" :key="team.id">
          <m-icon icon="groups"/>
          <span class="name">{{ team.name }} <span v-if="team.steward === user.name">(steward)</span></span>
        </li>
      </ul>
    </template>
  </Popup>
</template>

<style lang="scss" scoped>
  .message {
    margin-bottom: 1.5em;
  }

  .teams {
        min-height: 14rem;
        max-height: 20rem;
        overflow-y: auto;
    }

    .teams li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.5rem;

        &:last-child {
            margin-bottom: 0;
        }

        .name {
            flex: 1;
            margin: 0 0.5rem;
        }
    }
</style>