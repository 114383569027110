<script>
import { userMixins } from "../../../mixins/user.js";
import TeamSettings from "./TeamSettings.vue";
import SetTeamSteward from "@/components/pages/teams/SetTeamSteward";
import {mapState} from "vuex";

export default {
  mixins: [userMixins],
  props: ["item"],
  components: {
    SetTeamSteward,
    TeamSettings,
  },
  data() {
    return {
      isEditing: false,
      isSharing: false,
    };
  },
  computed: {
    ...mapState({
      isAdmin: (state) => state.auth.userData?.isAdmin ?? false,
    }),
  },
  methods: {
    teamSelectHandler() {
      this.isEditing = true;
    },
    editClosed(changed) {
      this.isEditing = false;
      if (changed) {
        this.$emit('modified');
      }
    },
    sharingClosed(changed) {
      this.isSharing = false;
      if (changed) {
        this.$emit('modified');
      }
    },
  },
};
</script>

<template>
  <tbody>
  <tr class="item" @click.prevent="teamSelectHandler">
    <td class="icon">
      <m-icon class="material-icons-outlined" icon="groups" />
    </td>
    <td class="name">
      <a href="#" @click.prevent.stop="teamSelectHandler">{{ item.name }}</a>

      <TeamSettings v-if="isEditing" :item="item" @closed="editClosed" icon="groups"/>
    </td>
    <td class="steward">
      <a href="#" @click.prevent.stop="teamSelectHandler">{{ item.steward }}</a>
    </td>
    <td class="numberOfMembers">
      <a href="#" @click.prevent.stop="teamSelectHandler">{{ item.numberOfMembers }}</a>
    </td>
    <td class="actions" v-if="isAdmin">
      <button @click.stop="isSharing = true">{{ $t("set-steward") }}</button>

      <SetTeamSteward v-if="isSharing" :team="item" @closed="sharingClosed" />
    </td>
  </tr>
  </tbody>
</template>

<style lang="scss" scoped>
tr.item {
  cursor: pointer;

  .actions {
    text-align: right;
    white-space: nowrap;
  }

  .actions > * {
    visibility: hidden;
  }

  &:hover, &.opened {
    background: #EEEEEE;

    .actions > * {
      visibility: visible;
    }

  }

  .icon {
    color: #4F4F4F;
  }
}

button {
  margin: -0.5rem 0 -0.5rem 1rem;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  background: #DCDCDC;
}

</style>
