<script>
import { apiMixins } from "../../../mixins/api.js";

export default {
  props: {
    item: {
      type: Object,
      required: false,
    },
    availableRetentionSchedules: {
      type: Array,
      required: true
    },
    currentRetentionSchedule: {
      type: Object,
      required: false,
    }
  },
  mixins: [apiMixins],
  emits: ["retention-schedule-changed"],
  data() {
    return {
      availableCategories: [],
      availablePeriods: [],
      workingRetentionSchedule: this.currentRetentionSchedule,
      selectedRetentionSchedule: null,
      workingRegion: "default",
      workingCategory: null,
      workingPeriod: null,
      workingRetentionScheduleId: null,
      parsedItemSchedule: this.item && this.item.retSchedule && this.item.retSchedule.replace("#", ""),
    };
  },
  computed: {
    filteredRetentionSchedules() {
      if (this.workingRegion) {
        return this.availableRetentionSchedules.filter(r => r.region === this.workingRegion);
      }
      if (this.workingPeriod) {
        return this.availableRetentionSchedules.filter(r => r.period === this.workingPeriod);
      }
      if (this.workingCategory) {
        return this.availableRetentionSchedules.filter(r => r.category === this.workingCategory);
      }
      return this.availableRetentionSchedules;
    },
  },
  methods: {
    onCategoryChangedHandler() {
      this.workingPeriod = null;
    },
    onPeriodChangedHandler() {
      this.workingCategory = null;
    },
    onRetentionScheduleChanged(event) {
      this.selectedRetentionSchedule = this.availableRetentionSchedules.find(r => r.id === event.value);
      this.$emit("retention-schedule-changed", this.selectedRetentionSchedule);
    },
    isCurrentItemRetentionScheduleEmpty() {
      return !this.currentRetentionSchedule;
    }
  },
  mounted() {
    const categories = this.availableRetentionSchedules
            .map((retentionSchedule) => retentionSchedule.category)
            .sort();
    const uniqueCategories = [...new Set(categories)];
    this.availableCategories = uniqueCategories;

    const periods = this.availableRetentionSchedules
            .map(r => r.period)
            .sort();
    const uniquePeriods = [...new Set(periods)];
    this.availablePeriods = uniquePeriods;
    
    if (!this.isCurrentItemRetentionScheduleEmpty()) {
      this.workingRegion = this.workingRetentionSchedule.region;
      this.workingCategory = this.workingRetentionSchedule.category;
      this.workingPeriod = this.workingRetentionSchedule.period;
      this.workingRetentionScheduleId = this.workingRetentionSchedule.id;
    }
  },
};
</script>

<template>
  <div>
    <div class="retention-block">
      <label for="select-retention-schedule-period" class="retention-label"
        >Region:</label
      >
      <m-select
        id="select-retention-schedule-region"
        outlined
        v-model="workingRegion"
        class="retention-select"
      >
        <option value="default">Default Region</option>
        <option value="svk">SVK Region</option>
      </m-select>
    </div>
    <div class="retention-block" style="display:none">
      <label for="select-retention-schedule-period" class="retention-label"
        >{{ $t("retention-schedule-picker.filter-by-period-label") }}</label
      >
      <m-select
        id="select-retention-schedule-period"
        outlined
        v-model="workingPeriod"
        class="retention-select"
        @change="onPeriodChangedHandler"
      >
        <option value="">{{ $t("retention-schedule-picker.default-period-option") }}</option>
        <option
          v-for="period in availablePeriods"
          :key="period"
          :value="period"
          :selected="period === workingPeriod"
          >{{ period }}</option
        >
      </m-select>
    </div>
    <div class="retention-block" style="display:none">
      <label for="select-retention-schedule-category" class="retention-label"
        >{{ $t("retention-schedule-picker.filter-by-category-label") }}</label
      >
      <m-select
        id="select-retention-schedule-category"
        outlined
        v-model="workingCategory"
        class="retention-select"
        @change="onCategoryChangedHandler"
      >
        <option value="">{{ $t("retention-schedule-picker.default-category-option") }}</option>
        <option
          v-for="category in availableCategories"
          :key="category"
          :value="category"
          :selected="category === workingCategory"
          >{{ category }}</option
        >
      </m-select>
    </div>
    <div class="retention-block">
      <label for="select-retention-schedule" class="retention-label"
        >{{ $t("retention-schedule-picker.available-schedules-label") }}</label
      >
      <m-select
        id="select-retention-schedule"
        outlined
        v-model="workingRetentionScheduleId"
        class="retention-select"
        @change="onRetentionScheduleChanged"
      >
        <option value="">{{ $t("retention-schedule-picker.default-schedule-option") }}</option>
        <option
          v-for="schedule in filteredRetentionSchedules"
          :key="schedule.id"
          :value="schedule.id"
          :selected="schedule.id === workingRetentionScheduleId"
          >{{ schedule.id }}: - {{ schedule.title }} ({{
            schedule.period
          }})</option
        >
      </m-select>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.retention-select {
  width:100%;
  margin-top:0.5rem;
  margin-left: 0.5rem;
}

.retention-label {
  font-weight:bold;
}

.retention-block {
  margin-bottom:1rem;
}

</style>
