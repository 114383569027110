<script>
import Teams from "./Teams.vue";
import { mapState } from "vuex";

export default {
  components: {
    Teams,
  },
  computed: {
    ...mapState({
    }),
  },
  mounted() {
    this.$store.dispatch('setBreadcrumbs', [{ to: { name: 'teams' }, label: this.$t('teams') }]);
  },
};
</script>

<template>
  <Teams />
</template>
