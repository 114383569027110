<script>
import {formatISODate} from "@/util/date";
import DeactivateUser from "./DeactivateUser.vue";
import Membership from "./Membership.vue";
import {mapState} from "vuex";

export default {
  props: ["user"],
  ...mapState({
      isAdmin: (state) => state.auth.userData?.isAdmin ?? false,
    }),
  components: {
    DeactivateUser, Membership
  },
  data() {
    return {
      isDeactivating: false,
      isMembership: false,
    };
  },
  methods: {
    formatCreatedOnDate(createdOnISODate){
      return formatISODate(createdOnISODate);
    },
    deactivatingClosed(changed) {
      this.isDeactivating = false;
      if (changed) {
        this.$emit('modified');
      }
    },
    membershipClosed() {
      this.isMembership = false;
    },
  },
};
</script>

<template>
  <tbody>
    <tr class="user" @click.prevent="$emit('selected')">
      <td class="icon">
        <m-icon icon="person" />
      </td>
      <td class="name">
        <a href="#" @click.prevent.stop="$emit('selected')">{{ user.name }}</a>
      </td>
      <td>{{ formatCreatedOnDate(user.created) }}</td>
      <td>{{ user.numberOfTeams }}</td>
      <td>{{ user.stewards }}</td>
      <td class="actions">
      <button @click.stop="isMembership = true">{{ $t("membership-user") }}</button>
      <button @click.stop="isDeactivating = true">{{ $t("deactivate-user") }}</button>

      <DeactivateUser v-if="isDeactivating" :user="user" @closed="deactivatingClosed" />
      <Membership v-if="isMembership" :user="user" @closed="membershipClosed" />
      </td>
    </tr>
  </tbody>
</template>

<style lang="scss" scoped>
tr.user {
  cursor: pointer;

  .actions {
    text-align: right;
    white-space: nowrap;
  }

  .actions > * {
    visibility: hidden;
  }

  &:hover, &.opened {
    background: #EEEEEE;

    .actions > * {
      visibility: visible;
    }

  }

  .icon {
    color: #4F4F4F;
  }
}

button {
  margin: -0.5rem 0 -0.5rem 1rem;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  background: #DCDCDC;
}
</style>