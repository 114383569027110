<script>
import { notificationMixins } from "../../../mixins/notification";
import Popup from "../../common/Popup.vue";
import {userMixins} from "@/mixins/user";

export default {
  mixins: [userMixins, notificationMixins],
  props: {
    team: {
      type: Object,
      required: true,
    },
  },
  components: {
    Popup,
  },
  data() {
    return {
      stewardName: "",
    };
  },
  mounted() {
    this.stewardName = this.team.steward;
  },
  methods: {
    async shareConfirmed() {
      if (this.stewardName !== this.team.steward) {
        await this.setTeamSteward(this.team.id, this.stewardName);
        this.showSuccess(this.$t("steward-changed"));
      }
      this.$emit("closed", true);
    },
  },
};
</script>

<template>
  <Popup @cancelled="$emit('closed', false)" @confirmed="shareConfirmed">
    <template v-slot:header>{{ $t("set-steward") }}</template>
    <template v-slot:body>
      <ul class="users">
        <li>
          <m-text-field v-model="stewardName" class="input" outlined required>
            <m-floating-label>{{ $t("share-folder-steward-placeholder") }}</m-floating-label>
          </m-text-field>
        </li>
      </ul>
    </template>
  </Popup>
</template>

<style lang="scss" scoped>
.users li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  i {
    flex: 0 0 auto;
  }

  .name {
    flex: 1;
    margin: 0 0.5rem;
  }

  &.me .name::after {
    content: "(me)";
    color: #767676;
    margin-left: 0.5rem;
    font-size: 0.875em;
  }

  .type {
    flex: 0 0 auto;
    color: #767676;
  }

  .input {
    width: 100%;
  }
}
</style>
