<script>
  import {notificationMixins} from "../../../mixins/notification";
  import Popup from "../../common/Popup.vue";
  import {userMixins} from "../../../mixins/user";

  export default {
    mixins: [notificationMixins, userMixins],
    props: {
      item: {
        type: Object,
        required: true,
      },
      icon: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        addMemberName: "",
        steward: "",
        members: [],
      };
    },
    components: {
      Popup,
    },
    mounted() {
      this.getTeamMembers(this.item.id)
        .then(({steward, members}) => {
          this.steward = steward;
          this.members = members;
        });
    },
    methods: {
      async addConfirmed() {
        await this.addUsersToTeam(this.item.id, this.members);
        this.showSuccess(this.$t("team-saved"));
        this.$emit("closed", true);
      },
      addMember() {
        console.log(this.addMemberName);
        const name = this.addMemberName;
        if (!name) {
          return;
        }
        this.addMemberName = "";
        if (this.members.find((member) => (member === name))) {
          return;
        }
        this.checkTeam({q: name}).then((team) => {
          if (!team.isPerson && !team.isAutomationUser){
            this.showError(this.$t("cannot-add-team-to-team"));
            return;
          }
            this.members = [name, ...this.members];
        })
      },
      addMembers() {
        const membersToAdd = this.addMemberName.split(/[\s,;|]+/);
        membersToAdd.forEach(member => {
          if (member.trim() === null || !member.trim().length) {
            return;
          }
          this.addMemberName = member.trim().toLowerCase();
          this.addMember();
        });
      },
    removeMember(name) {
        this.members = this.members.filter(member => member !== name);
      },
    },
  };
</script>

<template>
    <Popup @cancelled="$emit('closed')" @confirmed="addConfirmed" :icon="icon">
        <template v-slot:header><span class="prefix">{{ $t("edit-team-title") }}:</span> {{item.name}}</template>
        <template v-slot:body>
            <form @submit.stop.prevent="addMembers" class="popup-section">
                <label class="entry-field">
                    <span>{{ $t("user-add") }}</span>
                    <input type="text" v-model="addMemberName" :placeholder="$t('user-add-placeholder')"/>
                    <button class="primary" @click="addMembers" :disabled="addMemberName.length===0">
                      {{ $t("add-member-button") }}
                    </button>
                </label>
                <p class="description">{{ $t("add-member-description") }}</p>
                <p class="description">{{ $t("add-member-description-2") }}</p>
            </form>
            <ul class="users popup-section">
                <li>
                  <m-icon icon="person"/>
                  <span class="name">{{ steward }}</span>
                  <span class="type">Steward</span>
                </li>
                <h2>Members:</h2>
                <li v-for="user in members" :key="user">
                    <m-icon icon="person"/>
                    <span class="name">{{ user }}</span>
                    <button @click="removeMember(user)"><m-icon class="material-icons-outlined" icon="highlight_off" /></button>
                </li>
            </ul>
        </template>
    </Popup>
</template>

<style lang="scss" scoped>
    .entry-field {
        display: flex;
        align-items: center;

        span {
            flex: 0 0 auto;
            font-weight: bold;
        }

        input {
            margin: -0.5rem 0 -0.5rem 0.5rem;
            padding: 0.5rem;
            flex: 1;
        }

        button {
          background: transparent;
          border: 1px solid #1275CC;
          color: #1275CC;
          border-radius: 4px;
          padding: 0.3rem 0.5rem;
          margin-left: 0.3rem;
          font-size: 0.9rem;
          font-weight: bold;
          cursor: pointer;
          line-height: 1.6rem;

          &:disabled {
            cursor: default;
            opacity: 0.2;
          }

          &.primary {
            background: #1275CC;
            border-color: transparent;
            color: white;
          }

          > i {
            vertical-align: -0.35rem;
            padding-right: 0.25rem;
          }
        }
    }
    .users {
        min-height: 14rem;
        max-height: 20rem;
        overflow-y: auto;
        margin-bottom: 1rem;
    }

    .users li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.5rem;

        &:last-child {
            margin-bottom: 0;
        }

        i {
            flex: 0 0 auto;
        }

        .name {
            flex: 1;
            margin: 0 0.5rem;
        }

        .type, button {
          flex: 0 0 auto;
          color: #767676;
       }
    }

    .prefix {
        font-weight: normal;
    }

    h2 {
      font-weight: bold;
      padding-bottom: 1rem;
    }

    button {
      cursor: pointer;
    }

    p.description {
      margin-top: 1rem;
      font-style: italic;
    }

</style>
