<script>
import { userMixins } from "../../../mixins/user.js";
import Pagination from "../../common/Pagination";
import TeamItem from "./TeamItem.vue";
import Popup from "../../common/Popup.vue";
import {mapState} from "vuex";
import ResultsFilter from "../../common/ResultsFilter.vue";

export default {
  mixins: [userMixins],
  components: {
    Pagination,
    TeamItem,
    Popup,
    ResultsFilter,
  },
  data() {
    return {
      page: 0,
      pageSize: 25,
      isNewTeam: false,
      newTeamName: "",
      currentSort: 'name',
      currentSortDir: 'asc',
      filter: "",
    };
  },
  asyncComputed: {
    teams() {
      return this.getTeams({
        page: this.page,
        pageSize: this.pageSize,
        sort: this.currentSort + ',' + this.currentSortDir,
        // loader MUST NOT be used here, as the async watching behaviour causes an infinite loop
        // See https://github.com/foxbenjaminfox/vue-async-computed/issues/108
        showLoader: false,
        filter: this.filter
      });
    },
  },
  computed: {
    ...mapState({
      isAdmin: (state) => state.auth.userData?.isAdmin ?? false,
    }),
    pages() {
      if (!this.teams) {
        return 0;
      }
      return Math.ceil(this.teams.totalItemCount / this.pageSize);
    },
    sortIcon(){
      if (this.currentSortDir === 'asc') { return "arrow_downward";}
      else return "arrow_upward";
    }
  },
  methods: {
    refresh() {
      this.$asyncComputed.teams.update();
    },
    setPage(i) {
      this.page = i;
    },
    setPageSize(newPageSize) {
      this.pageSize = newPageSize;
    },
    setFilter(newFilter) {
      this.page = 0;
      this.filter = newFilter;
    },
    addTeamHandler() {
      this.isNewTeam = true;
      this.newTeamName = "";
    },
    async newTeamConfirmed() {
      await this.addNewTeam(this.newTeamName);
      this.isNewTeam = false;
      this.refresh();
    },
    sort(s) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
        this.currentSort=s;
    },
    hasRecords() {
      return this.teams && this.teams.teams && this.teams.teams.length !== 0;
    }
  },
};
</script>

<template>
  <div class="teams">
    <portal to="actions">
      <ResultsFilter @filterChange="setFilter"/>
      <button v-if="isAdmin" class="primary" @click="addTeamHandler">
        <m-icon icon="groups" />
        {{ $t("new-team-button") }}
      </button>
    </portal>

    <Popup :valid="newTeamName.length > 2" v-if="isNewTeam" @cancelled="isNewTeam = false" @confirmed="newTeamConfirmed">
      <template v-slot:header>{{ $t("new-team-title") }}</template>
      <template v-slot:body>
        <m-text-field v-model="newTeamName" outlined required>
          <m-floating-label>{{ $t("new-team-name-placeholder") }}</m-floating-label>
        </m-text-field>
      </template>
    </Popup>

    <p v-if="$asyncComputed.teams.updating" class="loading"></p>
    <p v-else-if="$asyncComputed.teams.error" class="load-error">Failed to load teams</p>
    <p v-else-if="!hasRecords()">There are no teams. Try creating one.</p>
    <table v-else class="listing">
      <thead>
        <tr>
          <th class="icon"></th>
          <th class="name" @click="sort('name')">Name
              <span v-if="currentSort == 'name'">
                <m-icon v-bind:icon="sortIcon" class="sort-icon" />
              </span>
          </th>
          <th class="steward" @click="sort('steward')">Steward
              <span v-if="currentSort == 'steward'">
                <m-icon v-bind:icon="sortIcon" class="sort-icon" />
              </span>
          </th>
          <th class="steward">Members
          </th>
        </tr>
      </thead>
      <template v-for="item in teams.teams">
        <TeamItem :key="item.id" :item="item" @modified="refresh" />
      </template>
    </table>
    <Pagination v-if="hasRecords()" 
      :page="page" 
      :pages="pages" 
      :pageSize="pageSize" 
      :totalRecords="teams.totalItemCount" 
      @pageChange="setPage" 
      @pageSizeChange="setPageSize" />
  </div>
</template>

<style lang="scss" scoped>
.loading, .load-error {
  text-align: center;
  margin-top: 4rem;
}

.sort-icon {
  font-size: 14px;
}

.listing {
  width: 100%;
  font-size: 0.875rem;

  th {
    font-weight: bold;
    color: #5B5B5B;
  }

  th.icon {
    width: 1px;
  }

  ::v-deep tbody {
    background: white;

    td {
      border-bottom: 1px solid #EBEBEB;
    }
  }

  th, ::v-deep td {
    padding: 1rem 0.75rem;

    &:first-child {
      padding-left: 1rem;
    }
    &:last-child {
      padding-right: 1.5rem;
    }
  }

}
</style>
