<script>
import Properties from "./Properties.vue";
import { folderPathCacheMixins } from "../../../mixins/folderPathCache";

export default {
  mixins: [folderPathCacheMixins],
  components: {
    Properties,
  },
  methods: {
    async updateBreadcrumbs() {
      let id;
      if (this.$route.params.item !== undefined) {
        id = this.$route.params.item.id;
      } else {
        id = window.location.pathname.replace("/properties/", "");
      }
      const path = await this.getFolderPathCached(id);
      await this.$store.dispatch("setBreadcrumbs", [
        { to: { name: "repositories" }, label: this.$t("repositories") },
        ...path.map((item) => ({
          to: { name: "browse", params: { id: item.id } },
          label: item.name,
        })),
      ]);
    },
  },
  mounted() {
    this.updateBreadcrumbs();
  },
  watch: {
    '$route.params.id'() {
      this.updateBreadcrumbs();
    },
  },
};
</script>

<template>
  <Properties :item="$route.params.item" />
</template>
